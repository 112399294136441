import type { ProviderGetImage } from "@nuxt/image";
import ThumborUrl from "thumbor-ts";

export const getImage: ProviderGetImage = (src, { modifiers = {} } = {}) => {
  const runtimeConfig = useRuntimeConfig();

  const thumbor = ThumborUrl({
    serverUrl: runtimeConfig.public.thumborServerUrl,
    securityKey: runtimeConfig.public.thumborSecurityKey,
  });

  const width = modifiers.width || 136;
  const height = modifiers.height || 136;

  const url = decodeURIComponent(
    decodeURIComponent(
      thumbor
        .setPath(`${width}x${height}/smart/filters:format(webp)/${src}`)
        .buildUrl(),
    ),
  );

  return {
    url,
  };
};
