<template>
  <div class="AvatarGroup__wrapper">
    <div
      v-for="(avatar, index) in avatarsProps"
      :key="index"
      class="AvatarGroup__border"
      :class="borderClass"
    >
      <Avatar :data="avatar" />
    </div>
  </div>
</template>
<script setup lang="ts">
import { Profile } from "~/types";

type Props = {
  participantsCount: number;
  usersProfiles: Profile[];
  borderClass?: string;
};

const props = defineProps<Props>();
const avatarsProps = computed(() => {
  if (!props.usersProfiles) {
    return;
  }
  const avatars = props.usersProfiles.slice(0, 3).map((profile) => {
    if (profile.avatar_url && profile.avatar_url.length > 0) {
      return {
        variant: "IMAGE",
        size: "24px",
        imageUrl: profile.avatar_url,
      } as const;
    }
    return {
      variant: "USERNAME",
      size: "24px",
      username: profile.username,
    } as const;
  });

  if (props.participantsCount >= 4) {
    const numberProps = {
      variant: "NUMBER",
      size: "24px",
      number: props.participantsCount - 3,
    } as const;

    return [...avatars, numberProps] as const;
  }
  return avatars;
});
</script>
<style scoped lang="scss">
.AvatarGroup {
  &__wrapper {
    display: flex;
    flex-direction: row;
  }
  &__border {
    border-radius: 32px;
    border: 2px solid $color-element-light-strong;
    margin-left: -6px;
    &.dark-blue {
      border: 2px solid $brand-primary-700;
    }
  }
}
</style>
