<template>
  <div>
    <div v-if="isImageVariant" :class="avatarClass" class="Avatar__container">
      <NuxtImg class="Avatar__image" :src="imageUrl" alt="avatar" />
    </div>
    <div
      v-if="isUsernameVariant"
      :class="avatarClass"
      class="Avatar__container"
    >
      {{ avatarText }}
    </div>
    <div v-if="isNumberVariant" :class="avatarClass" class="Avatar__container">
      {{ avatarText }}
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed } from "vue";
type Size = "54px" | "40px" | "34px" | "24px";
type Props = {
  data:
    | {
        variant: "IMAGE";
        size: Size;
        imageUrl: string;
      }
    | {
        variant: "USERNAME";
        size: Size;
        username: string;
      }
    | {
        variant: "NUMBER";
        size: Size;
        number: number;
      };
};

const props = defineProps<Props>();
const isImageVariant = computed(() => {
  return props.data.variant === "IMAGE";
});
const isUsernameVariant = computed(() => {
  return props.data.variant === "USERNAME";
});
const isNumberVariant = computed(() => {
  return props.data.variant === "NUMBER";
});

const avatarClass = computed(() => {
  if (props.data.variant === "NUMBER") {
    switch (props.data.size) {
      case "54px":
        return "size-xl long";
      case "40px":
        return "size-l long";
      case "34px":
        return "size-m long";
      case "24px":
        return "size-s long";
      default:
        return "size-s long";
    }
  }
  switch (props.data.size) {
    case "54px":
      return "size-xl";
    case "40px":
      return "size-l";
    case "34px":
      return "size-m";
    case "24px":
      return "size-s";
    default:
      return "size-s";
  }
});

const avatarText = computed(() => {
  if (props.data.variant === "USERNAME") {
    return props.data.username[0];
  }
  if (props.data.variant === "NUMBER") {
    return `+${props.data.number}`;
  }
  throw new Error("Invalid Avatar props");
});

const imageUrl = computed(() => {
  if (props.data.variant === "IMAGE") {
    return props.data.imageUrl;
  }
  throw new Error("Invalid Avatar props");
});
</script>
<style scoped lang="scss">
.Avatar {
  &__image {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
  &__container {
    background: $color-primary-background;
    color: $color-element-dark-strong;
    display: flex;
    justify-content: center;
    align-items: center;
    &.size-xl {
      width: 54px;
      height: 54px;
      border-radius: 50%;
      @include label-xxl-bold;
      &.long {
        background: $color-element-light-strong;
        color: $color-element-dark-medium;
      }
    }
    &.size-l {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      @include label-l;
      &.long {
        width: 60px;
        border-radius: 40px;
        background: $color-element-light-strong;
        color: $color-element-dark-medium;
      }
    }
    &.size-m {
      width: 34px;
      height: 34px;
      border-radius: 50%;
      @include label-s;
      &.long {
        width: 41px;
        border-radius: 34px;
        background: $color-element-light-strong;
        color: $color-element-dark-medium;
      }
    }
    &.size-s {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      @include label-xs;
      &.long {
        width: 42px;
        border-radius: 32px;
        background: $color-element-light-strong;
        color: $color-element-dark-medium;
      }
    }
  }
}
</style>
